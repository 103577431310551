import { ActivityQuery } from './__generated__/ActivityQuery.graphql'
import { graphql, useLazyLoadQuery } from 'react-relay'
import CurrentActivity from './CurrentActivity/CurrentActivity'
import GenerateActivity from './Generate'
import SelectActivity from './SelectActivity'
import Waiting from './Waiting'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { useEffect } from 'react'

function Activity() {
  const ActivityQuery = graphql`
    query ActivityQuery {
      learner {
        currentAssignment {
          id
        }
        availableAssignments {
          id
        }
        readyForAssignments
        ...CurrentActivity_learner
        ...GenerateActivity_learner
        ...SelectActivity_learner
      }
    }
  `
  const data = useLazyLoadQuery<ActivityQuery>(
    ActivityQuery,
    {},
    { fetchPolicy: 'network-only' }
  )
  const learner = data?.learner
  const queryParams = useSearch({ from: '/lmsRootRoute/lms/activity' })

  // strip query string params
  const navigate = useNavigate()
  useEffect(() => {
    navigate({
      to: '/lms/activity',
      replace: true,
    })
  }, [navigate])

  if (learner?.currentAssignment) {
    return <CurrentActivity learner={learner} complete={queryParams.complete} />
  } else if (learner?.availableAssignments.length) {
    return <SelectActivity learner={learner} />
  } else if (learner?.readyForAssignments) {
    return <GenerateActivity learner={learner} />
  } else {
    return <Waiting />
  }
}
export default Activity
