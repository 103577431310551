/**
 * @generated SignedSource<<869b80118f5a958d3703efd768d9b0c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LMSRootQuery$variables = {};
export type LMSRootQuery$data = {
  readonly learner: {
    readonly " $fragmentSpreads": FragmentRefs<"Header_learner">;
  } | null;
};
export type LMSRootQuery = {
  response: LMSRootQuery$data;
  variables: LMSRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v3 = [
  (v0/*: any*/)
],
v4 = [
  (v1/*: any*/),
  (v0/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showChoices",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LMSRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Header_learner"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LMSRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnerAchievementCategory",
            "kind": "LinkedField",
            "name": "achievementCategories",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnerAchievement",
                "kind": "LinkedField",
                "name": "achievements",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "acknowledged",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completionDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Achievement",
                    "kind": "LinkedField",
                    "name": "achievement",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completionDescription",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "showCompletion",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageUrl",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "completedAssignments",
            "args": [
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "completed": true
                }
              }
            ],
            "concreteType": "AssignmentConnection",
            "kind": "LinkedField",
            "name": "assignments",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": "assignments(filter:{\"completed\":true})"
          },
          {
            "alias": "currentAssignments",
            "args": [
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "completed": false
                }
              }
            ],
            "concreteType": "AssignmentConnection",
            "kind": "LinkedField",
            "name": "assignments",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": "assignments(filter:{\"completed\":false})"
          },
          {
            "alias": "availableAssignments",
            "args": [
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "available": true
                }
              }
            ],
            "concreteType": "AssignmentConnection",
            "kind": "LinkedField",
            "name": "assignments",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": "assignments(filter:{\"available\":true})"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "readyForAssignments",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locale",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonalityTest",
            "kind": "LinkedField",
            "name": "personalityTest",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "receivingActivities",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "localizedLogoUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnerInitiativeConnection",
            "kind": "LinkedField",
            "name": "initiatives",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Initiative",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "currentIniatives",
            "args": [
              {
                "kind": "Literal",
                "name": "membershipFilter",
                "value": {
                  "atDate": 0
                }
              }
            ],
            "concreteType": "LearnerInitiativeConnection",
            "kind": "LinkedField",
            "name": "initiatives",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Initiative",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InitiativeConnection",
                    "kind": "LinkedField",
                    "name": "choices",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DaysOverdueContestLearnerConnection",
            "kind": "LinkedField",
            "name": "daysOverdueContests",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DaysOverdueContestLearner",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DaysOverdueContest",
                    "kind": "LinkedField",
                    "name": "contest",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "active",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SurveyResponse",
            "kind": "LinkedField",
            "name": "pendingSurvey",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SurveyAssignment",
            "kind": "LinkedField",
            "name": "surveyAwaitingResponse",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnerPathway",
            "kind": "LinkedField",
            "name": "currentPathway",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Pathway",
                "kind": "LinkedField",
                "name": "pathway",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "visible",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnerConnection",
            "kind": "LinkedField",
            "name": "directReports",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Treatment",
            "kind": "LinkedField",
            "name": "treatments",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Experiment",
                "kind": "LinkedField",
                "name": "experiment",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c18136c90b8a72f81a14d6d16d40a8d0",
    "id": null,
    "metadata": {},
    "name": "LMSRootQuery",
    "operationKind": "query",
    "text": "query LMSRootQuery {\n  learner {\n    ...Header_learner\n    id\n  }\n}\n\nfragment Header_learner on Learner {\n  ...NewAchievementDialogFragment\n  completedAssignments: assignments(filter: {completed: true}) {\n    totalCount\n  }\n  currentAssignments: assignments(filter: {completed: false}) {\n    totalCount\n  }\n  availableAssignments: assignments(filter: {available: true}) {\n    totalCount\n  }\n  readyForAssignments\n  locale\n  personalityTest {\n    id\n  }\n  receivingActivities\n  fullName\n  localizedLogoUrl\n  customer {\n    name\n    id\n  }\n  initiatives {\n    nodes {\n      id\n      showChoices\n    }\n  }\n  currentIniatives: initiatives(membershipFilter: {atDate: 0}) {\n    nodes {\n      id\n      showChoices\n      choices {\n        totalCount\n      }\n    }\n  }\n  daysOverdueContests {\n    nodes {\n      contest {\n        id\n        active\n        startDate\n      }\n    }\n  }\n  pendingSurvey {\n    id\n  }\n  surveyAwaitingResponse {\n    id\n  }\n  currentPathway {\n    pathway {\n      visible\n      id\n    }\n    id\n  }\n  directReports {\n    totalCount\n  }\n  treatments {\n    name\n    experiment {\n      name\n      id\n    }\n    id\n  }\n  achievementCategories {\n    achievements {\n      acknowledged\n    }\n  }\n}\n\nfragment NewAchievementDialogFragment on Learner {\n  achievementCategories {\n    achievements {\n      acknowledged\n      completionDate\n      achievement {\n        id\n        name\n        completionDescription\n        showCompletion\n        imageUrl\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f6a1d50dbbe61b492048e70f8082af14";

export default node;
