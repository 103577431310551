/**
 * @generated SignedSource<<f1777c564654659f6b40ec50879e6f65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectActivityMutation$variables = {
  assignmentID: string;
};
export type SelectActivityMutation$data = {
  readonly selectAssignment: {
    readonly id: string;
    readonly learner: {
      readonly " $fragmentSpreads": FragmentRefs<"CurrentActivity_learner" | "SelectActivity_learner">;
    };
  } | null;
};
export type SelectActivityMutation = {
  response: SelectActivityMutation$data;
  variables: SelectActivityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assignmentID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "assignment",
    "variableName": "assignmentID"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instructions",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longDescription",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillSet",
  "kind": "LinkedField",
  "name": "competency",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Customer",
  "kind": "LinkedField",
  "name": "customer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "competencyLabel",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Assignment",
        "kind": "LinkedField",
        "name": "selectAssignment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Learner",
            "kind": "LinkedField",
            "name": "learner",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CurrentActivity_learner"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SelectActivity_learner"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Assignment",
        "kind": "LinkedField",
        "name": "selectAssignment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Learner",
            "kind": "LinkedField",
            "name": "learner",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Assignment",
                "kind": "LinkedField",
                "name": "currentAssignment",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "choiceDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Activity",
                    "kind": "LinkedField",
                    "name": "activity",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "guidance",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SkillConnection",
                        "kind": "LinkedField",
                        "name": "behaviors",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Skill",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "notes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "generatedReasoning",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              {
                "alias": "currentInitiative",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "membershipFilter",
                    "value": {
                      "atDate": 0
                    }
                  }
                ],
                "concreteType": "LearnerInitiativeConnection",
                "kind": "LinkedField",
                "name": "initiatives",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  }
                ],
                "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Treatment",
                "kind": "LinkedField",
                "name": "treatments",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Experiment",
                    "kind": "LinkedField",
                    "name": "experiment",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Assignment",
                "kind": "LinkedField",
                "name": "availableAssignments",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Activity",
                    "kind": "LinkedField",
                    "name": "activity",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SkillConnection",
                        "kind": "LinkedField",
                        "name": "behaviors",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Skill",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Learner",
                    "kind": "LinkedField",
                    "name": "learner",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c99a228ddb6eeeef6082b0089c7d5524",
    "id": null,
    "metadata": {},
    "name": "SelectActivityMutation",
    "operationKind": "mutation",
    "text": "mutation SelectActivityMutation(\n  $assignmentID: ID!\n) {\n  selectAssignment(assignment: $assignmentID) {\n    id\n    learner {\n      ...CurrentActivity_learner\n      ...SelectActivity_learner\n      id\n    }\n  }\n}\n\nfragment ActivityCard_assignment on Assignment {\n  activity {\n    name\n    instructions\n    shortDescription\n    behaviors {\n      nodes {\n        id\n        name\n        shortDescription\n        longDescription\n        competency {\n          id\n          name\n          shortDescription\n          longDescription\n        }\n      }\n    }\n    id\n  }\n  learner {\n    customer {\n      competencyLabel\n      id\n    }\n    id\n  }\n}\n\nfragment CurrentActivity_learner on Learner {\n  id\n  currentAssignment {\n    id\n    dueDate\n    choiceDate\n    activity {\n      guidance\n      name\n      shortDescription\n      instructions\n      behaviors {\n        nodes {\n          name\n          shortDescription\n          longDescription\n          competency {\n            id\n            name\n            shortDescription\n            longDescription\n          }\n          id\n        }\n      }\n      id\n    }\n    notes\n    generatedReasoning\n  }\n  customer {\n    competencyLabel\n    id\n  }\n  currentInitiative: initiatives(membershipFilter: {atDate: 0}) {\n    totalCount\n  }\n  treatments {\n    name\n    experiment {\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment SelectActivity_learner on Learner {\n  id\n  availableAssignments {\n    id\n    dueDate\n    ...ActivityCard_assignment\n  }\n  treatments {\n    name\n    experiment {\n      name\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cbc9d9942b51652575f804484f30b034";

export default node;
