/**
 * @generated SignedSource<<2ad0cf36fc26f41bf1ae2375ffde2c86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Header_learner$data = {
  readonly achievementCategories: ReadonlyArray<{
    readonly achievements: ReadonlyArray<{
      readonly acknowledged: boolean;
    }>;
  }>;
  readonly availableAssignments: {
    readonly totalCount: number;
  };
  readonly completedAssignments: {
    readonly totalCount: number;
  };
  readonly currentAssignments: {
    readonly totalCount: number;
  };
  readonly currentIniatives: {
    readonly nodes: ReadonlyArray<{
      readonly choices: {
        readonly totalCount: number;
      };
      readonly id: string;
      readonly showChoices: boolean;
    }>;
  };
  readonly currentPathway: {
    readonly pathway: {
      readonly visible: boolean;
    };
  } | null;
  readonly customer: {
    readonly name: string;
  };
  readonly daysOverdueContests: {
    readonly nodes: ReadonlyArray<{
      readonly contest: {
        readonly active: boolean;
        readonly id: string;
        readonly startDate: number;
      };
    }>;
  };
  readonly directReports: {
    readonly totalCount: number;
  };
  readonly fullName: string | null;
  readonly initiatives: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly showChoices: boolean;
    }>;
  };
  readonly locale: string;
  readonly localizedLogoUrl: string | null;
  readonly pendingSurvey: {
    readonly id: string;
  } | null;
  readonly personalityTest: {
    readonly id: string;
  } | null;
  readonly readyForAssignments: boolean;
  readonly receivingActivities: boolean;
  readonly surveyAwaitingResponse: {
    readonly id: string;
  } | null;
  readonly treatments: ReadonlyArray<{
    readonly experiment: {
      readonly name: string;
    };
    readonly name: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"NewAchievementDialogFragment">;
  readonly " $fragmentType": "Header_learner";
};
export type Header_learner$key = {
  readonly " $data"?: Header_learner$data;
  readonly " $fragmentSpreads": FragmentRefs<"Header_learner">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showChoices",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Header_learner",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewAchievementDialogFragment"
    },
    {
      "alias": "completedAssignments",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "completed": true
          }
        }
      ],
      "concreteType": "AssignmentConnection",
      "kind": "LinkedField",
      "name": "assignments",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "assignments(filter:{\"completed\":true})"
    },
    {
      "alias": "currentAssignments",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "completed": false
          }
        }
      ],
      "concreteType": "AssignmentConnection",
      "kind": "LinkedField",
      "name": "assignments",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "assignments(filter:{\"completed\":false})"
    },
    {
      "alias": "availableAssignments",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "available": true
          }
        }
      ],
      "concreteType": "AssignmentConnection",
      "kind": "LinkedField",
      "name": "assignments",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "assignments(filter:{\"available\":true})"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readyForAssignments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locale",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonalityTest",
      "kind": "LinkedField",
      "name": "personalityTest",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receivingActivities",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedLogoUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerInitiativeConnection",
      "kind": "LinkedField",
      "name": "initiatives",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Initiative",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "currentIniatives",
      "args": [
        {
          "kind": "Literal",
          "name": "membershipFilter",
          "value": {
            "atDate": 0
          }
        }
      ],
      "concreteType": "LearnerInitiativeConnection",
      "kind": "LinkedField",
      "name": "initiatives",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Initiative",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "InitiativeConnection",
              "kind": "LinkedField",
              "name": "choices",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DaysOverdueContestLearnerConnection",
      "kind": "LinkedField",
      "name": "daysOverdueContests",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DaysOverdueContestLearner",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DaysOverdueContest",
              "kind": "LinkedField",
              "name": "contest",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "active",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startDate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyResponse",
      "kind": "LinkedField",
      "name": "pendingSurvey",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyAssignment",
      "kind": "LinkedField",
      "name": "surveyAwaitingResponse",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerPathway",
      "kind": "LinkedField",
      "name": "currentPathway",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Pathway",
          "kind": "LinkedField",
          "name": "pathway",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "visible",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerConnection",
      "kind": "LinkedField",
      "name": "directReports",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Treatment",
      "kind": "LinkedField",
      "name": "treatments",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Experiment",
          "kind": "LinkedField",
          "name": "experiment",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerAchievementCategory",
      "kind": "LinkedField",
      "name": "achievementCategories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerAchievement",
          "kind": "LinkedField",
          "name": "achievements",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "acknowledged",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "6a889bd72defceea17ac8559301a783e";

export default node;
