import { graphql, useFragment } from 'react-relay'
import { NextActionButtonLearnerFragment$key } from './__generated__/NextActionButtonLearnerFragment.graphql'
import { Link } from '@tanstack/react-router'
import { Button } from '@/components/Button'

const LearnerFragment = graphql`
  fragment NextActionButtonLearnerFragment on Learner {
    pendingSurvey {
      id
    }
    surveyAwaitingResponse {
      id
    }
    currentAssignment {
      id
    }
    availableAssignments {
      id
    }
    readyForAssignments
  }
`

type NextActionButtonProps = {
  learnerRef: NextActionButtonLearnerFragment$key
  pulse?: boolean
}

export function NextActionButton({
  learnerRef,
  pulse = false,
}: NextActionButtonProps) {
  const learner = useFragment(LearnerFragment, learnerRef)

  // logic copied from LMSRedirect.tsx
  const buttonType =
    learner.pendingSurvey || learner.surveyAwaitingResponse
      ? 'survey'
      : learner.currentAssignment
        ? 'current-activity'
        : learner.availableAssignments.length || learner.readyForAssignments
          ? 'select-activity'
          : 'none'

  return buttonType !== 'none' ? (
    <Link
      to={buttonType === 'survey' ? '/lms/general-survey' : '/lms/activity'}
    >
      <Button className={`gap-2 ${pulse ? 'animate-pulse' : ''}`}>
        {buttonType === 'survey'
          ? 'Complete Survey'
          : buttonType === 'select-activity'
            ? 'Select Next Activity'
            : 'Continue Activity'}
      </Button>
    </Link>
  ) : null
}
