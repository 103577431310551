import { type FormEvent, useState } from 'react'
import { graphql, useMutation } from 'react-relay'
import { Button } from '@/components/Button'

const Mutation = graphql`
  mutation ProvideEmailMutation($email: String!) {
    provideEmail(email: $email) {
      id
    }
  }
`

function ProvideEmail() {
  const [login, submitting] = useMutation(Mutation)
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    login({
      variables: {
        email,
      },
      onCompleted: () => {
        setSuccess(true)
      },
    })
  }

  return (
    <>
      <div className="relative flex min-h-screen flex-1 flex-col items-center justify-center bg-slate-50 py-2 font-sans text-slate-600">
        <div className="w-full max-w-sm rounded-3xl border border-slate-100 bg-white p-12 shadow-lg shadow-flintBlue/5">
          <img
            src="/logo.svg"
            alt="Flint Logo"
            width={320}
            height={320}
            className="mx-auto mb-6 w-24"
          />
          {success ? (
            <div className="space-y-4">
              <p>
                Thanks! We&apos;ve sent a verification email to{' '}
                <u>
                  <var>{email}</var>
                </u>
                . Click the link inside to complete your signup.
              </p>
              <p className="text-sm text-slate-400">
                If that was the wrong address,{' '}
                <button
                  className="cursor-pointer underline"
                  onClick={() => {
                    setEmail('')
                    setSuccess(false)
                  }}
                >
                  click here
                </button>{' '}
                to try again.
              </p>
            </div>
          ) : (
            <>
              <p className="mb-6 text-center">
                To proceed, please provide your email address.
              </p>
              <form className="space-y-6" onSubmit={handleSubmit}>
                <input
                  type="email"
                  required
                  placeholder="Enter your email"
                  className="block w-full rounded-md border-0 p-2.5 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-flintBlue sm:text-sm sm:leading-6"
                  disabled={success || submitting}
                  aria-disabled={success || submitting}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <Button
                  className="w-full"
                  type="submit"
                  variant={submitting ? 'loading' : 'default'}
                  disabled={success || submitting}
                >
                  Send Verification
                </Button>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ProvideEmail
