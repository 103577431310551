import { useEffect, useRef } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'
import { Navigate } from '@tanstack/react-router'

import Spinner from '@/components/Spinner'
import { Card } from '@/components/Card'
import InteractionProvider from '@/components/Interaction'

import { type GenerateActivity_learner$key } from './__generated__/GenerateActivity_learner.graphql'
import { type GenerateActivityMutation } from './__generated__/GenerateActivityMutation.graphql'

const Fragment = graphql`
  fragment GenerateActivity_learner on Learner {
    availableAssignments {
      id
    }
    readyForAssignments
  }
`

// TODO: Refactor activity page fragments. (Should there be only one,
// representing a complete update of the learner's activity state?)
const Mutation = graphql`
  mutation GenerateActivityMutation {
    generateAssignments {
      id
      learner {
        id
        ...CurrentActivity_learner
        ...GenerateActivity_learner
        ...SelectActivity_learner
      }
    }
  }
`

type GenerateActivityProps = { learner: GenerateActivity_learner$key }

export default function GenerateActivity({
  learner: key,
}: GenerateActivityProps) {
  const learner = useFragment(Fragment, key)
  const attempted = useRef(false)
  const [generate] = useMutation<GenerateActivityMutation>(Mutation)

  const ready = learner?.readyForAssignments

  useEffect(() => {
    if (ready && !attempted.current) {
      attempted.current = true
      generate({
        variables: {},
      })
    }
  }, [generate, ready])

  if (!ready && !attempted.current) {
    return <Navigate to="/lms" />
  }

  return (
    <InteractionProvider page="generate-activity">
      <div className="flex flex-col p-8">
        <Card className="max-w-2xl space-y-6 p-8">
          <div className="flex items-center space-x-4">
            <Spinner className="h-8 w-8 text-flintBlue" />
            <h1 className="text-2xl font-semibold text-flintBlue">
              Generating your next activities
            </h1>
          </div>
          <div className="space-y-3">
            <div className="h-2 overflow-hidden rounded-full bg-gray-100">
              <div className="h-full animate-[fill_5s_linear_forwards] bg-flintBlue" />
            </div>
            <p className="text-gray-600">
              We&apos;re selecting activities based on your learning profile.
            </p>
          </div>
        </Card>
      </div>
    </InteractionProvider>
  )
}
