/**
 * @generated SignedSource<<12977c29f6dac088ca82ac687222f61d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NextActionButtonLearnerFragment$data = {
  readonly availableAssignments: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly currentAssignment: {
    readonly id: string;
  } | null;
  readonly pendingSurvey: {
    readonly id: string;
  } | null;
  readonly readyForAssignments: boolean;
  readonly surveyAwaitingResponse: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "NextActionButtonLearnerFragment";
};
export type NextActionButtonLearnerFragment$key = {
  readonly " $data"?: NextActionButtonLearnerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NextActionButtonLearnerFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NextActionButtonLearnerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyResponse",
      "kind": "LinkedField",
      "name": "pendingSurvey",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyAssignment",
      "kind": "LinkedField",
      "name": "surveyAwaitingResponse",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Assignment",
      "kind": "LinkedField",
      "name": "currentAssignment",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Assignment",
      "kind": "LinkedField",
      "name": "availableAssignments",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readyForAssignments",
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "51e9bf103f6ccf242688c7da53cee147";

export default node;
